//========================
// define base colors
//========================
// dont set this to a color that can be interpreted as danger or warning
$primary: #31A2D2 !default;
$secondary: #00143B !default;
$dark: #373A3C !default;
$light: #EFEFEF !default;

//========================
// define theme colors
//========================
$theme-1: $primary !default;
$theme-2: $secondary !default;
$theme-colors: (
        'theme-1': $theme-1,
        'theme-2': $theme-2
);
$light-accent: $light;

//========================
// general theme options
//========================
$footer-header-color: $secondary !default;
$card-header-color: $secondary !default;
$card-link-color: $primary !default;
$link-decoration: none;
$border-radius: 0; // radius for cards, images and buttons
$line-height-base: 1.65;

//========================
// define flavours
//========================
// Always add preserveAspectRatio="none" to the .svg
$use-header-flavour: true; // flavour-header.svg
$use-footer-flavour: true; // flavour-footer.svg
$use-background-pattern: false; // flavour-pattern.png
$use-background-flavour: false;  // flavour-image.svg

//========================
//define text properties
//========================
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400;1,600&display=swap');
@font-face {
  font-family: "Americane Condensed Bold Italic";
  src: url('../font/Americane-Condensed-Web-Bold-Italic.eot') format("eot"),
  url('../font/Americane-Condensed-Web-Bold-Italic.woff') format("woff"),
  url('../font/Americane-Condensed-Web-Bold-Italic.woff2') format("woff2");
  font-weight: normal;
  font-style: normal;
}
$font-family-base: 'Poppins', sans-serif;
$headings-font-family: "Americane Condensed Bold Italic", Verdana, sans-serif;
$headings-font-weight: 900;
$display-font-weight: 400;
$headings-color: $primary;
$headings-text-transform: none; // default 'none'

//========================
//define button properties
//========================
$btn-text-transform: uppercase; // default 'none'
$btn-font-weight: 300;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-footer-style: theme-1;
$btn-font-family: "Americane Condensed Bold Italic", Verdana, sans-serif;
$language-selector-btn-variant: outline-theme-1;

//========================
//define banner spots properties
//========================
$accordion-banner-bg-color: theme-1; // Ect: theme-1, primary, white
$accordion-banner-text-color: white;


@import '../../../../src/scss/main.scss';

@import '../../../../src/scss/themes/template-barometer.scss';
